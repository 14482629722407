export const codeObject = {
  python: [
    '  class Person:',
    '    def __init__(self,name,dateOfBirth=2000):',
    '      self.name="Kyle Nguyen"',
    '      self.age=datetime.datetime.now().year-dateOfBirth',
  ],
  java: [
    '  public class Person:{',
    '      String final name;',
    '      int final dateOfBirth;',
    '      public Person(String name,int dateOfBirth){',
    '        this.name="Kyle Nguyen";',
    '        this.age=Calendar.getInstance()',
    '          .get(Calendar.YEAR)-dateOfBirth}}',
  ],
  js: [
    '  class Person{',
    '    constructor(name,dateOfBirth=2000){',
    '      this.name="Kyle Nguyen"',
    '      this.age = new Date().getFullYear() - dateOfBirth',
    '      }',
    '  }',
  ],
};

module.exports = {
  siteTitle: 'Kyle Nguyen',
  siteDescription:
    'Kyle Nguyen is an Aspring Fullstack Javascript Engineer, based in Ha Noi, Viet Nam, who loves learning new things and helping tech beginners.',
  siteKeywords:
    'Kyle Nguyen, trgkyle, software engineer, web developer, fullstack developer, fullstack engineer, fullstack web, javascript, typescript, docker, kubernetes',
  siteUrl: 'https://d1lm3y3okgrfvw.cloudfront.net',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'G-1QY7C788VC',
  googleVerification: 'E14A6h_fDP1EDmIKGeM72M_e7qLTikec_RNFl8Xmtds',
  name: 'Kyle Nguyen',
  location: 'Hanoi, Viet Nam',
  email: 'kylenyan1215@gmail.com',
  github: 'https://github.com/trgkyle',
  twitterHandle: '@',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/trgkyle',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/trgkyle/',
    },
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/trgkyle/',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/trgkyle',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Projects',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { FormattedIcon } from '@components/icons';
import styled from 'styled-components';
import { theme, mixins, media, Section } from '@styles';
const { colors, fontSizes, fonts } = theme;

const StyledTableContainer = styled.div`
  width: 100%;
  margin: 100px -20px;
  ${media.tablet`
    margin: 100px -10px;
  `};
`;
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  .hide-on-mobile {
    ${media.tablet`
      display: none;
    `};
  }

  tbody tr {
    transition: ${theme.transition};

    &:hover,
    &:focus {
      background-color: ${colors.lightNavy};
    }
  }
  th,
  td {
    cursor: default;
    line-height: 1.5;
    padding: 10px 20px;
    ${media.tablet`
      padding: 10px;
    `};
  }
  th {
    text-align: left;
  }
  td {
    &.year {
      width: 10%;
      ${media.tablet`
        font-size: ${fontSizes.sm};
      `};
    }
    &.title {
      padding-top: 15px;
      color: ${colors.lightestSlate};
      font-size: ${fontSizes.xl};
      font-weight: 700;
    }
    &.company {
      width: 15%;
      padding-top: 15px;
      font-size: ${fontSizes.lg};
    }
    &.tech {
      font-size: ${fontSizes.xs};
      font-family: ${fonts.SFMono};
      .separator {
        margin: 0 5px;
      }
      span {
        display: inline-block;
      }
    }
    &.links {
      span {
        display: flex;
        align-items: center;
        a {
          ${mixins.flexCenter};
        }
        a + a {
          margin-left: 10px;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
`;
const StyledContainer = styled(Section)`
  ${mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
`;
const StyledTitle = styled.h4`
  margin: 0 auto;
  font-size: ${fontSizes.h3};
  ${media.tablet`font-size: 24px;`};
  a {
    display: block;
  }
`;

const Projects = ({ data }) => {
  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  const revealTable = useRef(null);

  useEffect(() => {
    sr.reveal(revealTitle.current, srConfig());
    sr.reveal(revealTable.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const projects = data.filter(({ node }) => node);

  return (
    <StyledContainer>
      <header ref={revealTitle}>
        <StyledTitle className="big-title">Other Noteworthy Repositories</StyledTitle>
        <p className="subtitle">List of things I’ve worked on</p>
      </header>

      <StyledTableContainer ref={revealTable}>
        <StyledTable>
          <thead>
            <tr>
              <th>Year</th>
              <th>Title</th>
              <th className="hide-on-mobile">Made at</th>
              <th className="hide-on-mobile">Built with</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            {projects.length > 0 &&
              projects.map(({ node }, i) => {
                const { date, github, external, title, tech, company } = node.frontmatter;
                return (
                  <tr key={i} ref={el => (revealProjects.current[i] = el)}>
                    <td className="overline year">{`${new Date(date).getFullYear()}`}</td>

                    <td className="title">{title}</td>

                    <td className="company hide-on-mobile">
                      {company ? <span>{company}</span> : <span>—</span>}
                    </td>

                    <td className="tech hide-on-mobile">
                      {tech.length > 0 &&
                        tech.map((item, i) => (
                          <span key={i}>
                            {item}
                            {''}
                            {i !== tech.length - 1 && <span className="separator">&middot;</span>}
                          </span>
                        ))}
                    </td>

                    <td className="links">
                      <span>
                        {external && (
                          <a
                            href={external}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            aria-label="External Link">
                            <FormattedIcon name="External" />
                          </a>
                        )}
                        {github && (
                          <a
                            href={github}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            aria-label="GitHub Link">
                            <FormattedIcon name="GitHub" />
                          </a>
                        )}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </StyledTable>
      </StyledTableContainer>
    </StyledContainer>
  );
};

Projects.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Projects;
